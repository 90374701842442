import React, {Component} from 'react';
import {Progress} from 'reactstrap';
import classes from './ProgressBar.module.scss';
import CountUp from "react-countup";
import {fundingMessage, scaleVotesToProgress} from "./ProgressBarFunctions";

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voteCount: 0
        }
        this.progressBarWidth = React.createRef();
    }

    componentDidMount() {
        this.setState({ voteCount: this.props.voteCount });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.voteCount !== this.props.voteCount){
            this.setState({voteCount: this.props.voteCount})
        }
    }

    renderBubble(scaledProgress, BadgeDynamicStyles, pointerDynamicStyles){
        return (
            <React.Fragment>
                <h3 style={BadgeDynamicStyles} className={"bg-secondary text-light rounded p-1 " + classes.badge} color="text-secondary">
                    {this.state.voteCount >= 0 ? <CountUp start={0} end={this.state.voteCount} duration={2.4} separator="" delay={1}/> : '-'} Stimmen
                </h3>
                <div style={pointerDynamicStyles} className={classes.Pointer}/>
            </React.Fragment>
        );
    };

    render() {
        // Use threshold 2 to define the progressbar limit.
        // where threshold 2 is 90% of the progressbar.scale of progress from:  0% to 90%
        let currentFundingGoalText = '...';
        let thresholdMark1 = 0;
        let scaledProgress = 0;
        const THRESHOLD_MARK_2 = 90;
        const PROGRESS_BAR_CONTAINER_WIDTH = this.progressBarWidth.current ? this.progressBarWidth.current.clientWidth : 0;
        if(this.props.threshold2 > 0){
            thresholdMark1 = (this.props.threshold1 / this.props.threshold2) * 90;
            scaledProgress =  scaleVotesToProgress(this.state.voteCount, this.props.threshold2);
        }
        const BadgeDynamicStyles = { transform: `translateX(-${scaledProgress}%)` };
        const pointerDynamicStyles = { transform: `rotate(${(19 - (38 / 100 * scaledProgress))}deg)` }
        const textGradientStop = () => { return (scaledProgress / 100 * PROGRESS_BAR_CONTAINER_WIDTH) -16; }
        let textGradientStyle = { background: `linear-gradient(to right, #fff 0%, #fff ${textGradientStop() + 'px'}, #333 ${textGradientStop() + 'px'}, #333 100%)` }
        if(this.props){
            const {voteCount, fundingIncrement1, fundingIncrement2, threshold1, threshold2, projectBalance} = this.props;
            currentFundingGoalText = fundingMessage(voteCount, fundingIncrement1, fundingIncrement2, threshold1, threshold2, projectBalance);
        }

        return(
            <React.Fragment>
                <div className={this.props.cssClasses} ref={this.progressBarWidth}>
                    <Progress className={classes.progress} style={{height: "1.5rem"}} multi>
                        <Progress bar className={classes.progressBar + ` ${(scaledProgress === 100 && " rounded ")}`} color="primary" value={scaledProgress}>
                            {this.props.bubble && this.renderBubble(scaledProgress, BadgeDynamicStyles, pointerDynamicStyles)}
                        </Progress>
                        {!this.props.bubble &&
                            <h6 style={textGradientStyle} className={"text-uppercase " + classes.progressText}>
                                {this.state.voteCount >= 0 ? this.state.voteCount : '-'} Stimmen
                            </h6>
                        }
                    </Progress>
                    <div className={classes.progressLabels}>
                        <small style={{left: `${thresholdMark1}%`}} className={classes.threshold1Label}>{this.props.threshold1}</small>
                        <small style={{left: `${THRESHOLD_MARK_2}%`}} className={classes.threshold2Label}>{this.props.threshold2}</small>
                        <div style={{left: `${thresholdMark1}%`}} className={classes.thresholdLine}/>
                        <div style={{left: `${THRESHOLD_MARK_2}%`}} className={classes.thresholdLine}/>
                    </div>
                </div>
                <div className="mt-4 d-flex justify-content-end">
                    {currentFundingGoalText}
                </div>
            </React.Fragment>
        );
    }
}

export default ProgressBar;
