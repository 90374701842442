import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

import Input from '../../components/UI/Input/FormInput';
import { Row, Col, Button, Form, FormGroup } from 'reactstrap';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { checkValidity } from "../../shared/utility";

class Auth extends Component {

    state = {
        controls : {
            contactEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'E-Mail-Adresse',
                    label: 'E-Mail-Adresse',
                    autoComplete: 'email',
                    columns: 12
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                errorMessage: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
                touched: false
            },
            contactPassword: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Passwort',
                    label: 'Passwort',
                    autoComplete: 'current-password',
                    columns: 12
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                errorMessage: 'Bitte geben Sie Ihr Passwort ein',
                touched: false
            }
        }
    };

    onChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({controls: updatedControls});
    };

    onSubmitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.controls.contactEmail.value, this.state.controls.contactPassword.value);
    };

    render () {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key, // Left part of projectForm objects (i.e. projectTitle)
                config: this.state.controls[key] // Right part of projectForm objects (i.e. elementConfig{...})
            });
        }

        let form = formElementsArray.map(formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                changed={(event) => this.onChangedHandler(event, formElement.id)}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                errorMessage={formElement.config.errorMessage}
                invalid={!formElement.config.valid} />
        ));

        if (this.props.loading) {
            // Todo: Implement spinner / placeholder while auth is running
            form = 'loading...';
        }

        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to="/" />
        }

        return (
            <Row>
                <Col>
                    {authRedirect}
                    <Form onSubmit={this.onSubmitHandler}>
                        {form}
                        <FormGroup row>
                            <Col sm={{size: 12}}>
                                <Button>Anmelden</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);