import React from "react";

// Statistic tracking scripts
export const statisticTracking = () => {
    return (
        <script
            src={`//static.etracker.com/code/e.js`}
            id={"_etLoader"}
            charSet={"UTF-8"}
            data-block-cookies={"false"}
            data-respect-dnt={"true"}
            data-secure-code={process.env.REACT_APP_ETRACKER_ID}>
        </script>
    );

    // Google Tag Manager tracking example
    /*
    <script>
    {`
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
         new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','${process.env.REACT_APP_GTAG_ID}');
    `}
    </script>
    */
};

// Statistic tracking scripts
export const personalization = () => {
    return (
        <link
            href={`https://fonts.googleapis.com/css2?family=Signika:wght@400;700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400&family=Source+Code+Pro:wght@600&display=swap`}
            rel={`stylesheet`}/>
    );
};
