export const scaleVotesToProgress = (currentVote, thresholdMark2) => {
    let scaledProgress = 0;
    if(thresholdMark2 > 0 && currentVote > 0){
        // use value of votes to scale progress according to threshold 2 and progress range
        scaledProgress = (currentVote * 90 / thresholdMark2);
        //if current vote is greater than threshold 2.
        if (scaledProgress > 100) {
            scaledProgress = 100;
        }
    }
    return scaledProgress;
}

export const fundingMessage = (voteCount, fundingIncrement1, fundingIncrement2, threshold1, threshold2, projectBalance) => {
    let currentFundingGoalText = '...';
    if(voteCount < threshold1) {
        currentFundingGoalText = <small className="text-muted">{threshold1} Stimmen knacken und {fundingIncrement1} Euro sichern!</small>;
    } else if(voteCount >= threshold1 && voteCount < threshold2) {
        currentFundingGoalText = <small className="text-muted">{threshold2} Stimmen knacken und weitere {fundingIncrement2} Euro sichern!</small>;
    } else {
        currentFundingGoalText = <small className="text-muted">Glückwunsch: {projectBalance} Euro Förderung gesichert!</small>;
    }
    return currentFundingGoalText;
}