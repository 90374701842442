import React from 'react';
import classes from './ProjectListItem.module.scss';
import {Card, CardBody, CardTitle, CardSubtitle, CardText, Col} from 'reactstrap';
import ProgressBar from "../../../components/UI/ProgressBar/ProgressBar";

const projectListItem = (props) => {
    return (
        <Col className={"mb-4 " + props.display} key={props._id}>
            <Card className="h-100 anl__project-list--item">
                <div className={classes.CardImage} style={{backgroundImage: `url(${props.image})`}}/>
                <CardBody className="d-flex flex-column">
                    <CardTitle tag="h2">{props.name}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{props.title}</CardSubtitle>
                    <CardText>{props.shortDescription}</CardText>
                    <ProgressBar
                        bubble={false}
                        projectBalance={props.projectBalance}
                        threshold1={props.threshold1}
                        threshold2={props.threshold2}
                        voteCount={props.voteCount}
                        fundingIncrement1={props.fundingIncrement1}
                        fundingIncrement2={props.fundingIncrement2}
                        phases={props.phases}
                        cssClasses={'mt-auto'}
                    />
                    {props.children}
                </CardBody>
            </Card>
        </Col>
    );
};

export default projectListItem;
