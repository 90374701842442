import React from 'react';
import {Table} from "reactstrap";

function SignUpPreviewList(props) {
    return (
        <React.Fragment>
            <Table size="sm" borderless hover responsive>
                <tbody>
                {Object.values(props.projectForm).map((item, index) => {
                    if(item.elementType !== 'checkbox' && item.elementConfig.type !== 'password') {
                        return(
                            <tr key={index}>
                                <th scope="row" className="w-50">{item.elementConfig.label}</th>
                                <td>{item.value}</td>
                            </tr>
                        );
                    } else {
                        return null
                    }
                })}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default SignUpPreviewList;
