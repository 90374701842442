import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'

const shareButton = (props) => {
    const voteCount = props.voteCount;
    const threshold1 = props.threshold1;
    const threshold2 = props.threshold2;
    const fundingIncrement1 = props.fundingIncrement1;
    const fundingIncrement2 = props.fundingIncrement2;
    let shareText;
    let shareUrl = window.location.href;

    if(voteCount < threshold1){
        shareText = encodeURIComponent(`Uns fehlen noch ${threshold1 - voteCount} Stimmen für eine Förderung von ${fundingIncrement1} Euro. Hilf uns das Förderungsziel zu erreichen und stimme jetzt für uns ab`);
    } else if (voteCount >= threshold1 && voteCount < threshold2 ) {
        shareText = encodeURIComponent(`Uns fehlen noch ${threshold2 - voteCount} Stimmen für eine Förderung von ${fundingIncrement2} Euro. Hilf uns das Förderungsziel zu erreichen und stimme jetzt für uns ab`);
    } else {
        shareUrl = window.location.origin + "/projekte/";
        shareText = encodeURIComponent(`Wir haben es geschafft und das Förderziel erreicht! Hier gibt es noch andere tolle Projekte denen wir helfen können ihr Förderziel zu erreichen`);
    }

    let button;
    switch (props.shareType) {
        case 'email':
            button = (
                <a
                    rel="noreferrer"
                    href={`mailto:?subject=Jetzt für uns abstimmen!&body=${shareText}: ${shareUrl}`}
                    target="_blank"
                    className="btn-outline-email btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" className="pe-1"/>Mailen
                </a>
            );
            break;
        case 'facebook':
            button = (
                <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                    target="_blank"
                    rel="external noopener noreferrer"
                    className="btn-outline-facebook btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faFacebook} size="lg" className="pe-1"/>Teilen
                </a>
            );
            break;
        case 'twitter':
            button = (
                <a
                    href={`https://twitter.com/share?url=${shareUrl}&text=${shareText}`}
                    target="_blank"
                    rel="external noopener noreferrer"
                    className="btn-outline-twitter btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faTwitter} size="lg" className="pe-1"/>Twittern
                </a>
            );
            break;
        case 'whatsApp':
            button = (
                <a
                    rel="noreferrer"
                    href={`whatsapp://send?text=${shareText}%20${shareUrl}`}
                    target="_blank"
                    className="btn-outline-whatsapp btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faWhatsapp} size="lg" className="pe-1"/>Teilen
                </a>
            );
            break;
        default:
            button = (
                <span>Kein Dienst definiert</span>
            );
            break;
    }

    return button;
};

export default shareButton;
