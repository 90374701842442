import React, {Component} from 'react';
import classes from './Page.module.scss';
import {createMarkup, sendVirtualPageView} from "../../shared/utility";
import {Col, Row, Alert} from 'reactstrap';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import KeyVisual from "../UI/ResponsiveImageSet/ResponsiveImageSet";
import KeyVisualXs from "../../assets/images/keyvisual-xs-rgb.jpg";
import KeyVisualLg from "../../assets/images/keyvisual-lg-rgb.jpg";
import SurveyHost from "../../containers/Survey/SurveyHost";
import Aux from "../../hoc/Aux/Aux";

class Page extends Component{
    componentDidMount() {
        library.add(fas);
        /**
         * Use of dom.watch() method to convert any of the icons provided by API to SVG tags
         * To avoid unnecessary css cruft in <head> dom.watch() is confined to this component's
         * function.
         *
         * The watch() method expects the to-be-watched DOM node to be present before method
         * invocation. Usually the parent component should contain said DOM node
         *
         * @param {Object} autoReplaceSvgRoot watch for a particular element
         * @param {Object} observeMutationsRoot watch for a particular element
         * https://fontawesome.com/how-to-use/with-the-api/methods/dom-watch
         */
        library.add(fas);
        dom.watch({
            autoReplaceSvgRoot: document.getElementById('dom-watch'),
            observeMutationsRoot: document.getElementById('dom-watch')
        });
        sendVirtualPageView(this.props.pageTitle)
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${this.props.pageTitle}`}</title>
                </Helmet>
                <Col>
                    <Row >
                        <Col className="px-0">
                            <KeyVisual imageXs={KeyVisualXs} imageLg={KeyVisualLg} />
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <article id="page-content" className={`${classes.Page} row`}><h1>{this.props.pageTitle}</h1>
                                {this.props.survey === "top" &&
                                    <Col className="mb-3 pb-3 mb-md-0 pb-md-0 border-bottom">
                                        <SurveyHost/>
                                    </Col>
                                }
                                <div dangerouslySetInnerHTML={createMarkup(this.props.pageBody)} />
                                {this.props.survey === "bottom" &&
                                    <Col className="mt-3 pt-3 mt-md-0 pt-md-0 border-top">
                                        <SurveyHost/>
                                    </Col>
                                }
                            </article>
                        </Col>
                    </Row>
                </Col>

            </React.Fragment>
        );
    }
}

export default Page;
