import React from 'react';
import {
    Card,
    CardBody, CardSubtitle, CardText,
    CardTitle,
    Col,
    Progress,
    Row
} from "reactstrap";
import classes from './SignUpPreview.module.scss';
import ContentLoader from "react-content-loader";


const SignUpPreview  = (props) => {
    return (
        <div className={classes.midFrame}>
            <div className={classes.innerFrame}>
                <i>Speaker</i>
                <b>Camera</b>
                <Row className="m-0">
                    <Col className="text-white bg-light text-center" style={{paddingTop: '2.5rem'}}>
                    </Col>
                </Row>
                <Row className="mx-1" style={{zoom: '60%'}}>
                    <Col className="col-12">
                        <ContentLoader
                            height={140}
                            width={390}
                            speed={2}
                            secondaryColor="#ecebeb"
                        >
                            <rect x="0" y="0" rx="5" ry="5" width="385" height="130" />
                        </ContentLoader>
                    </Col>
                    <Col className="col-12">
                        <Card className="">
                            <div className={classes.CardImage} style={{ backgroundImage: `url(${props.mediaUrl})` }}/>
                            <CardBody>
                                <CardTitle tag="h2">{props.contestantName.value}</CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">{props.projectTitle.value}</CardSubtitle>
                                <CardText>{props.projectShortDescription.value}</CardText>
                                <Progress color="primary" value={50} className={"mb-3"}/>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-12">
                        <ContentLoader
                            height={260}
                            width={390}
                            speed={2}
                            secondaryColor="#ecebeb">
                            <rect x="0" y="10" rx="5" ry="5" width="385" height="250" />
                        </ContentLoader>
                    </Col>
                </Row>
            </div>
            <p className="small text-muted text-center mt-3">Vorschau Ihres Projekts</p>
        </div>
    );
}

SignUpPreview.propTypes = {};
SignUpPreview.defaultProps = {
    mediaUrl: 'https://media.spendenwettbewerb.de/crowdvoting/crowd-voting-project-placeholder.svg',
    contestantName: {value: ''},
    projectTitle: {value: ''},
    projectShortDescription: {value: ''}
}

export default SignUpPreview;
