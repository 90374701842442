import React, {useEffect, useState} from 'react';
import {Row, Button , AccordionBody, Accordion, AccordionItem, AccordionHeader} from 'reactstrap';
import { Link } from "react-router-dom"
import {Helmet} from "react-helmet";
import {sendVirtualPageView} from "../../shared/utility";

function PageNotFound(props){
    const [open, setOpen] = useState([]);

    useEffect(() => {
        sendVirtualPageView("Seite nicht gefunden")
        props.pageNotFoundCallback(true);
        return () => {
           props.pageNotFoundCallback(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = (id) => {
        if (!open.includes(id)) {
            setOpen(prevOpen =>[...prevOpen, id]);
        } else {
            setOpen(prevOpen => prevOpen.filter(idToRemove => idToRemove !== id));
        }
    };

    const accordionItems = [
        {
            id: '1',
            header: "Hier geht es zur Abstimmung",
            body: "Sie möchten für Ihren Favoriten abstimmen? Hier entlang:",
            buttonText: "Jetzt abstimmen",
            buttonLink: "/projekte/"
        },
        {
            id: '2',
            header: "Hier geht es zur Bewerbung",
            body:"Sie möchten selbst teilnehmen? Einfach online anmelden:",
            buttonText: "Jetzt bewerben",
            buttonLink: "/bewerbung/"
        },
        {
            id: '3',
            header: "Alle Informationen zum Ablauf",
            body:"Sie möchten mehr über den Wettbewerb erfahren?",
            buttonText: "Jetzt informieren",
            buttonLink: "/wettbewerb/"
        }
    ];

    return(
        <React.Fragment>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Row className="d-flex flex-column my-2">
                <Accordion open={open} toggle={toggle}>
                    {accordionItems.map((item , index)=> (
                        <AccordionItem key={index} style={{ cursor: "pointer" }} >
                            <AccordionHeader targetId={item.id} >
                                {item.header}
                            </AccordionHeader>
                            <AccordionBody accordionId={item.id}>
                                <p>{item.body}</p>
                                <Button
                                    color="secondary"
                                    to={item.buttonLink}
                                    tag={Link}
                                    className="btn-block btn-md-inline-block">{item.buttonText}</Button>
                            </AccordionBody>
                        </AccordionItem>
                    ))}

                </Accordion>
            </Row>
        </React.Fragment>
    );
}

export default PageNotFound;
