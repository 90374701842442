import React, {Suspense} from 'react';

const Survey = React.lazy(() => import('./Survey'));

export default function SurveyHost() {
    return (
        <Suspense fallback={<div className="align-content-center align-self-center">Lädt...</div>}>
            <Survey mobileHeight={"120px"}/>
        </Suspense>
    );
}