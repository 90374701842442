import React, { Component } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import {Redirect} from 'react-router-dom';
import Aux from '../Aux/Aux';


const withErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {

        state = {
            error: null
        };

        UNSAFE_componentWillMount() {
            this.reqInterceptor = axios.interceptors.request.use( req => {
                this.setState( { error: null } );
                return req;
            } );
            this.resInterceptor = axios.interceptors.response.use( res => res, error => {
                this.setState( { error: error } );
            } );
        }

        componentWillUnmount () {
            axios.interceptors.request.eject( this.reqInterceptor );
            axios.interceptors.response.eject( this.resInterceptor );
        }

        errorConfirmedHandler = () => {
            // confirm error and thus set state.error to null again
            this.setState({error: null});
        };

        render() {
            let errorElement = (
                <Modal
                    show={this.state.error}
                    modalClosed={this.errorConfirmedHandler}>
                    {this.state.error && this.state.error.response && this.state.error.response.data ? (
                        <div dangerouslySetInnerHTML={{ __html: this.state.error.response.data }} />
                    ) : null}
                </Modal>
            )
            if(this.state.error && this.state.error.response.status === 404) {
                errorElement = <Redirect from="/projekte/:id" to="/wie-gehts-weiter" />;
            }
            return (
                <Aux>
                    {errorElement}
                    <WrappedComponent {...this.props} />
                </Aux>
            )
        }
    }
};

export default withErrorHandler;